import { API } from "./apiAuth";
// 연동관리 api
const getConnectApi = async (info, page) => {
    try {
      const response = await API.get(
        `manage/manage-connet-list?page=${page}&size=${info.size}&status=${info.status}&from_data=${info.from_data}&to_data=${info.to_data}&retri_id=${info.retri_id}&exchange=${info.exchange}&uid=${info.uid}`
      );
      return response.data;
    } catch (error) {
      return;
    }
  };
  // 연동 갯수 체크 api
  const getConnectCountApi = async () => {
    try {
      const response = await API.get(`manage/manage-connet-count`);
      return response.data;
    } catch (error) {
      return;
    }
  };
  // 연동 갯수 거래소별체크 api
  const getExcConnectCountApi = async (exchange) => {
    try {
      const response = await API.get(
        `manage/manage-exchange-connet-count?exchange=${exchange}`
      );
      return response.data;
    } catch (error) {
      return;
    }
  };
  export default {
    getConnectApi,
    getConnectCountApi,
    getExcConnectCountApi,
  }