<template>
  <div class="col-12 pl-10 pr-10 mb-20">
    <div class="tabMenu_inner border-0 mt-0">
      <ul class="tab_link_box ctgy02 diff">
        <li :class="{ active: select.exchange === 1 }" @click="changeTab(1)">OKX</li>
        <li :class="{ active: select.exchange === 2 }" @click="changeTab(2)">BingX</li>
        <li :class="{ active: select.exchange === 3 }" @click="changeTab(3)">
          Toobit
        </li>
        
        <li :class="{ active: select.exchange === 4 }" @click="changeTab(4)">
          DeepCoin
        </li>
      </ul>
    </div>
    <div class="card">
      <div class="card_header">
        <h5 class="card_title" v-if="select.exchange == 3"><i class="ti ti-file mr-5"></i> 파일 처리 현황 및 파일 등록</h5>
        <h5 class="card_title" v-else><i class="ti ti-file mr-5"></i> 파일 처리 현황</h5>
      </div>
      <div class="card_body">
        <!-- <h3>Trade Count</h3> -->
        <!-- <form actopm="/submit" method="post" enctype="multipart/form-data"> -->
        <div class="calendar_wrap">
          <div class="pf_calendar">
            <div class="cal_set">
              <h3 class="title">File Calendar</h3>
              <div class="cal_inner">
                <form action="" id="" name="">
                  <select id="year" name="year" v-model="select.year">
                    <option v-for="year in yearArr" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </select>
                  <select id="month" name="month" v-model="select.month">
                    <option v-for="i in 12" :key="i" :value="i">{{ i }}</option>
                  </select>
                </form>
              </div>
            </div>
            <TheCalender :select="select" />
          </div>
        </div>
        <div v-if="select.exchange == 3">
          <div class="file_area">
            <select v-model="select.exchange">
              <option value="3">Toobit</option>
            </select>
            <div class="file_input">
              <input
                id="userFile"
                type="file"
                @change="changeFile"
                accept=".xls,.xlsx"
              />
              <label for="userFile"
                ><i class="ti ti-paperclip"></i> {{ file_name }}</label
              >
            </div>
            <button type="submit" @click="fileRegister">업로드</button>
          </div>
          <p>(등록 가능한 파일 형식 : .xls , .xlsx)</p>
          <!-- </form> -->
          <div class="file_info">
            <p><i class="ti ti-bulb"></i>도움말</p>
            <p>-파일 선택 버튼을 클릭하여 파일을 선택합니다.</p>
            <p>
              -업로드 버튼을 클릭하면 팝업 화면으로 선택한 파일 정보가
              출력됩니다.
            </p>
            <p>
              -출력된 리소스를 확인 후 업로드버튼을 클릭하면 파일에 등록됩니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalMsg :msg="msg" v-if="modalState"/>
</template>
<script setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import ModalMsg from "@/components/modal/ModalMsg.vue";
import TheCalender from "@/components/public/TheCalender.vue";
const store = useStore();
const modalState = computed(() => store.state.admin.modalState );
const msg        = computed(() => store.state.referral.msg );
const nowDate = new Date();
let select = reactive({
  year: nowDate.getFullYear(),
  month: nowDate.getMonth() + 1,
  exchange : 1,
});
let yearArr = [nowDate.getFullYear(), nowDate.getFullYear() - 1];
let file_name = ref("파일을 선택하세요.");
let file;
const changeFile = (e) => {
  file_name.value = e.target.files[0]?.name;
  file = e.target.files[0];
};
const fileRegister = async () => {
  store.commit("referral/setFile", file);
  await store.dispatch("referral/postToobit");
  store.commit("referral/setMsg");
  store.commit("admin/changeModalState", true);
};
const changeTab = (state) => {
  select.exchange = state;
} 
</script>
