<template>
  <!-- modal & popup  검정배경-->
  <div class="modalBackColor"></div>
  <div class="modal_overlay">
    <!-- [모달창]:start-->
    <div class="modal_pop_small">
      <div class="smallPop popup_block pb-20">
        <div class="pop_title">
          <p>Password</p>
          <span class="pop_close" @click="closeModal">창닫기</span>
        </div>
        <form>
          <div class="con_wrap pw">
            <p>비밀번호 입력</p>
            <div class="input_group">
              <i
                :class="pwView1 ? 'pw_off' : 'pw_view'"
                @click="togglePwd($event, 1)"
              ></i>
              <input
                type="password"
                id="adminPw"
                name="adminPw"
                class="form_control"
                placeholder="············"
                autocomplete="off"
                v-model="adminInfo.pwd"
              />
            </div>
            <p>비밀번호 확인</p>
            <div class="input_group">
              <i
                :class="pwView2 ? 'pw_off' : 'pw_view'"
                @click="togglePwd($event, 2)"
              ></i>
              <input
                type="password"
                id="adminPwCheck"
                name="adminPwCheck"
                class="form_control"
                placeholder="············"
                autocomplete="off"
                v-model="adminInfo.pwdCheck"
              />
            </div>
          </div>
          <div class="col-12 mb-15 text-center">
            <button
              @click="editPwdSave"
              type="button"
              class="btn btn-primary mlr-5"
            >
              확인
            </button>
            <button
              @click="closeModal"
              type="reset"
              class="btn btn-secondary mlr-5"
            >
              취소
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- [모달창]:End -->
  </div>
  <ModalMsg :msg="msg" :callbackFn="closeModal" :callbackStatus="callbackStatus" v-if="modalState" />
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ModalMsg from "@/components/modal/ModalMsg.vue";
const store = useStore();
const props = defineProps({
  admin: { type: String },
  status: { type: Number },
});
const modalState = computed(() => {
  return store.state.admin.modalState;
});
const fetchStatus = computed(() => {
  return store.state.admin.fetchStatus;
});
let pwView1 = ref(false);
let pwView2 = ref(false);
let adminInfo = ref({
  pwd: "",
  pwdCheck: "",
});
let msg = ref("");
let callbackStatus = 0;

const togglePwd = (event, flag) => {
  const input = event.target.nextElementSibling;
  if (input.type === "password") {
    input.type = "text";
    flag == 1 ? (pwView1.value = true) : (pwView2.value = true);

  } else {
    input.type = "password";
    flag == 1 ? (pwView1.value = false) : (pwView2.value = false);
  }
};
const editPwdSave = async () => {
  if (
    adminInfo.value.pwd !== adminInfo.value.pwdCheck ||
    adminInfo.value.pwd == null ||
    adminInfo.value.pwd == ""
  ) {
    msg.value = "비밀번호가 올바르지 않습니다. 다시 입력해주세요.";
    callbackStatus = 0;
  } else {
    adminInfo.value.flag = props.status;
    store.commit("admin/setAdminId", props.admin);
    store.commit("admin/setAdminInfo", adminInfo.value);
    await store.dispatch("admin/updateAdminPwd");
    if (fetchStatus.value == 200) {
      msg.value = "성공적으로 변경하였습니다.";
      callbackStatus = 1;
      await store.dispatch("admin/getAdmin");
    } else {
      msg.value = "변경에 실패하였습니다. 다시 시도해주세요.";
      callbackStatus = 0;
    }
  }
  store.commit("admin/changeModalState", true);
};
const closeModal = async () => {
  store.commit("admin/changeAdminModal", false);
  store.commit("admin/setFetchStatus", null);
};
</script>
