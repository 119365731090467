<template>
  <div class="page_wrap">
    <ul class="pagination flex_center row">
      <li
        class="page-item previous paging_btn"
        v-if="props.page > 5 && props.page > 1"
        @click="props.changePage('prevEnd')"
      >
        &laquo;
      </li>
      <li
        class="page-item previous paging_btn"
        v-if="props.page > 5 && props.page > 1"
        @click="props.changePage('prev')"
      >
      &lsaquo;
      </li>
      <li 
        v-for="page in displayedPages"
        :key="page"
        @click="changePage(page)"
        class="page-item"
        :class="{ active: props.page === page }"
      >
        {{ page }}
      </li>
      <li
        class="page-item next paging_btn"
        v-if="props.totalPages > 5 && props.page < props.totalPages"
        @click="props.changePage('next')"
      >
      &rsaquo;
      </li>
      <li
        class="page-item next paging_btn"
        v-if="props.totalPages > 5 && props.page < props.totalPages"
        @click="props.changePage('nextEnd')"
      >
      &raquo;
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  page: { type: Number },
  changePage: { type: Function },
  totalPages: { type: Number },
});
const displayedPages = computed(()=>{
  if (props.totalPages <= 5) {
        return Array.from({ length: props.totalPages }, (_, i) => i + 1);
      } else {
        const startPage = Math.max(1, props.page - 2);
        const endPage = Math.min(props.totalPages, startPage + 4);
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
      }
});
</script>
