import { API } from "./apiAuth";

// 대시보드 api
const getDashBoardApi = async () => {
  try {
    const response = await API.get("manage/manage-dash");
    return response.data;
  } catch (error) {
    return;
  }
};
//총 연동 리스트
const getTotalMemberApi = async (info,page) => {
  try {
    const response = await API.get(`manage/manage-connet-total-list?page=${page}&size=${info.size}&exchange=${info.exchange}`);
    return response.data;
  } catch (error) {
    return;
  }
};
//오늘 연동 리스트
const getNewMemberApi = async (info,page) => {
  try {
    const response = await API.get(`manage/manage-today-total-list?page=${page}&size=${info.size}&exchange=${info.exchange}`);
    return response.data;
  } catch (error) {
    return;
  }
};
// uid 로그 api
const getUidLogApi = async (info, page) => {
  try {
    const response = await API.get(
      `manage/manage-uidconnet-list?page=${page}&size=${info.size}&status=${info.status}&from_data=${info.from_data}&to_data=${info.to_data}&user_no=${info.user_no}&exchange=${info.exchange}&uid=${info.uid}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};
export default {
  getDashBoardApi,
  getTotalMemberApi,
  getNewMemberApi,
  getUidLogApi,
};
