import axios from "axios";

export const API = axios.create({
  baseURL: "https://refapi.risingx.xyz/",
  headers: {
    Accept: "*/*",
  },
});


// 모든 api 요청 header 로 token 보내는 함수
function setAuthToken(id,tokenType) {
  const AUTH_TOKEN = `${tokenType} ${id}`;
  try {
    API.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    // console.log(API.defaults.headers.common.Authorization);
  } catch (error) {
    return;
  }
}

export default {
  setAuthToken,
};
