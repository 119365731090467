<template>
  <div class="modal_overlay" @click="closeModal">
    <div class="modal_pop_small">
      <div class="smallPop popup_block_large pb-20" @click.stop>
        <div class="pop_title mt-20">
          <p>
            {{ props.status == 1 ? "전체 유저 리스트" : "금일 가입한 유저 리스트" }}
          </p>
        </div>
        <div class="close_modal_btn" @click="closeModal"></div>
        <div class="con_wrap pt-80 pb-20">
          <div class="table-responsive text-nowrap dataTables_wrapper">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>USER_NO</th>
                  <th>UID</th>
                  <th>거래소</th>
                  <th>날짜</th>
                  <th>연동상태</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="memberList.length == 0">
                  <td colspan="7">데이터 내역이 없습니다.</td>
                </tr>
                <tr v-for="(data, i) in memberList" :key="i">
                  <td>{{ data.user_no }}</td>
                  <td>{{ data.uid }}</td>
                  <td>{{ exchangeNaming(data.exchange) }}</td>
                  <td>{{ data.datetime }}</td>
                  <td>{{ data.connet == 1 ? '승인 대기 중' : '승인 완료' }}</td>
                </tr>
              </tbody>
            </table>
            <ThePaging
               v-if="totalPages > 1"
              :page="page"
              :changePage="changePage"
              :totalPages="totalPages"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
import { computed } from "vue";
const store = useStore();
const props = defineProps({
  status: { type: Number },
});
const page       = computed(() => store.state.manage.page );
const totalPages = computed(() => store.state.manage.totalPages );
const memberList = computed(() => store.state.manage.memberList );
const closeModal = () => {
  store.commit("admin/changeModalState", false);
};
const exchangeNaming = (flag) => {
  let exchange;
  switch (flag) {
    case "1":
      exchange = "OKX";
      break;
    case "2":
      exchange = "BingX";
      break;
    case "3":
      exchange = "Toobit";
      break;
    case "4":
      exchange = "DeepCoin";
      break;
  }
  return exchange;
};
const createdFn = () => {
  store.commit("manage/setPage", 1);
  props.status == 1
    ? store.dispatch("manage/getTotalMember")
    : store.dispatch("manage/getNewMember");
};
const changePage = async (str) => {
  store.commit("manage/setPage", str);
  props.status == 1
    ? await store.dispatch("manage/getTotalMember")
    : await store.dispatch("manage/getNewMember");
};
createdFn();
</script>

<style lang="scss" scoped></style>
