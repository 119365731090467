import api from "@/api/apiConnect";

export default {
  namespaced: true,
  state() {
    return {
      reqConnectInfo: {},
      page: 1,
      isLoading: false,
      //res
      connectList: [],
      totalPages: 0,
      connectCountInfo: {},
      // fetchStatus : "",
    };
  },
  mutations: {
    setConnectInfo(state, payload) {
      state.reqConnectInfo = {
        size: 10,
        ...payload
      };
    },
    setPage(state, str) {
      if (str == "prev") {
        state.page = state.page - 5 <= 0 ? 1 : state.page - 5;
      } else if (str == "prevEnd") {
        state.page = 1;
      } else if (str == "next") {
        state.page = state.page + 5 > state.totalPages ? state.totalPages : state.page + 5;
      } else if (str == "nextEnd") {
        state.page = state.totalPages;
      } else {
        state.page = str;
      }
    },
  },
  actions: {
    async getConnect(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getConnectApi(
          context.state.reqConnectInfo,
          context.state.page
        );
        context.state.connectList = response.results;
        context.state.totalPages = response.totalPages;
        context.state.isLoading = false;
      } catch (error) {
        return;
      }
    },
    async getConnectCount(context) {
      try {
        let response = await api.getConnectCountApi();
        context.state.connectCountInfo = response.results[0];
      } catch (error) {
        return;
      }
    },
    async getExcConnectCount(context, exchange) {
      try {
        let response = await api.getExcConnectCountApi(exchange);
        context.state.connectCountInfo = response.results[0];
      } catch (error) {
        return;
      }
    },
  },
};
