import api from "@/api/apiClosing";
import { autoLeftPad } from "@/utils/common";

export default {
  namespaced: true,
  state() {
    return {
      reqInfo : {},
      reqMonthLogInfo: {},
      isLoading: false,
      page: 1,
      logWeekDate: "",
      logMonthDate: "",
      //res
      totalPages: 0,
      monthCountInfo: {},
      weekCountInfo: {},
      dailyCountInfo: {},
      fileExcel: "",
      filePdf: "",
      monthLogList: [],
      monthProfitList: [],
      dailyProfitList: [],
      totalProfitList: [],
      totalProfit: "",
      fetchStatus: "",
    };
  },
  mutations: {
    setInfo(state,payload){
      state.reqInfo = {
        size: 10,
        ...payload
      }
    },
    setPage(state, str) {
      if (str == "prev") {
        state.page = state.page - 5 <= 0 ? 1 : state.page - 5;
      } else if (str == "prevEnd") {
        state.page = 1;
      } else if (str == "next") {
        state.page = state.page + 5 > state.totalPages ? state.totalPages : state.page + 5;
      } else if (str == "nextEnd") {
        state.page = state.totalPages;
      } else {
        state.page = str;
      }
    },
    setLogWeekDate(state, payload) {
      state.logWeekDate = payload;
    },
    setLogMonthDate(state, payload) {
      state.logMonthDate = payload;
    },
    setMonthLogInfo(state, payload) {
      state.reqMonthLogInfo = {
        size: 10,
        date: payload.date,
        type: payload.type,
      };
    },
  },
  actions: {
    async getProfitMonth(context, exchange) {
      try {
        let date = new Date();
        let year_month = `${date.getFullYear()}-${autoLeftPad(
          date.getMonth() + 1,
          2
        )}`;
        let response = await api.getProfitMonthApi(exchange, year_month);
        context.state.monthCountInfo = response;
      } catch (error) {
        return;
      }
    },

    async getProfitPdf(context) {
      try {
        let response = await api.getProfitPdfApi(context.state.logMonthDate);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.filePdf = url;
      } catch (error) {
        return;
      }
    },
    async getProfitExcel(context) {
      try {
        let response = await api.getProfitExcelApi(context.state.logMonthDate);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.fileExcel = url;
      } catch (error) {
        return;
      }
    },
    async getWeekProfitPdf(context) {
      try {
        let response = await api.getWeekProfitPdfApi(context.state.logWeekDate);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.filePdf = url;
      } catch (error) {
        return;
      }
    },
    async getWeekProfitExcel(context) {
      try {
        let response = await api.getWeekProfitExcelApi(
          context.state.logWeekDate
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.fileExcel = url;
      } catch (error) {
        return;
      }
    },
    async getMonthLog(context) {
      try {
        context.state.totalPages = 1;
        let response = await api.getMonthLogApi(
          context.state.reqMonthLogInfo,
          context.state.page
        );
        context.state.monthLogList = response.result;
        context.state.totalPages = response.total_page;
      } catch (error) {
        return;
      }
    },
    async getUserProfit(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        context.state.reqInfo.user_no = Number(context.state.reqInfo.user_no)
        let response = await api.getUserProfitApi(
          context.state.reqInfo,
          context.state.page
        );
        context.state.monthProfitList = response.result;
        context.state.totalPages = response.total_page;
        context.state.isLoading = false;
      } catch (error) {
        return;
      }
    },
    async getTotalProfit(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        context.state.reqInfo.user_no = Number(context.state.reqInfo.user_no)
        let response = await api.getTotalProfitApi(
          context.state.reqInfo,
          context.state.page
        );
        context.state.totalProfitList = response.result;
        context.state.totalPages = response.total_page;
        context.state.totalProfit = response.total_profit;
        context.state.isLoading = false;
      } catch (error) {
        return;
      }
    },
    async getDailyProfit(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getDailyProfitApi(
          context.state.reqInfo,
          context.state.page
        );
        context.state.isLoading = false;
        if (response) {
          const { daily_profits, total_accumulated_profit, day_profit, before_total_profit, before_day_profit, total_pages } = response;
          context.state.dailyProfitList = Object.entries(daily_profits);
          context.state.dailyCountInfo = {
            total_accumulated_profit,
            day_profit,
            before_total_profit,
            before_day_profit,
          };
          context.state.totalPages = total_pages;
        } else {
          context.state.dailyProfitList = [];
        }
      } catch (error) {
        return;
      }
    },
    async getWeekExchange(context, exchange) {
      try {
        let response = await api.getWeekExchangeApi(exchange);
        context.state.weekCountInfo = response;
      } catch (error) {
        return;
      }
    },
    async getWeekIncome(context, admin) {
      try {
        let response = await api.getWeekIncomeApi(admin);
        context.state.fetchStatus = response.status;
      } catch (error) {
        return;
      }
    },
  },
};
