// @/views
import ViewReferral from "@/views/ViewReferral.vue";
import ViewClosing from "@/views/ViewClosing.vue";

// @/components
import DashBoard from "@/components/DashBoard.vue";
import TheLoginView from "@/components/public/TheLoginView.vue";

// @/components/admin
import AdminList from "@/components/admin/AdminList.vue";

// @/components/referral
import ReferralPayback from "@/components/referral/ReferralPayback.vue";
import ReferralRegister from "@/components/referral/ReferralRegister.vue";

// @/components/closing
import ClosingDaily from "@/components/closing/ClosingDaily.vue";
import ClosingWeek from "@/components/closing/ClosingWeek.vue";
// import ClosingMonth from "@/components/closing/ClosingMonth.vue";
// import ClosingLog from "@/components/closing/ClosingLog.vue";
import ClosingTotal from "@/components/closing/ClosingTotal.vue";

// @/components/connect
import ConnectSet from "@/components/connect/ConnectSet.vue";

// @/components/log
import LogSet from "@/components/log/LogSet.vue";

const routes = [
  // 처음 페이지 접속시 로딩되는 페이지
  { path: "/", redirect: "/dash-board" },
  // 로그인 페이지
  {
    path: "/login",
    name: "TheLoginView",
    component: TheLoginView,
    props: true,
  },
  // 대시보드 페이지
  {
    path: "/dash-board",
    name: "DashBoard",
    component: DashBoard,
    props: true,
  },
  // 관리자 정보 페이지
  {
    path: "/admin",
    name: "AdminList",
    component: AdminList,
    props: true,
  },
  // connect 탭
  {
    path: "/connect",
    name: "ConnectSet",
    component: ConnectSet,
    props: true,
  },
  // log 탭
  {
    path: "/log",
    name: "LogSet",
    component: LogSet,
    props: true,
  },
  // referral 탭
  {
    path: "/referral",
    component: ViewReferral,
    redirect: "/referral/payback",
    children: [
      { path: "payback", component: ReferralPayback },
      { path: "register", component: ReferralRegister },
    ],
  },
  // closing 탭
  {
    path: "/closing",
    component: ViewClosing,
    redirect: "/closing/daily",
    children: [
      { path: "daily", component: ClosingDaily },
      { path: "week", component: ClosingWeek },
      // { path: "month", component: ClosingMonth },
      // { path: "log", component: ClosingLog },
      { path: "total", component: ClosingTotal },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/dash-board" },
];

export default routes;
