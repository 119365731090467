<template>
    <div>
        <slot v-if="!hasError" />
        <ErrorFallBack :resetError="resetError" v-else />
    </div>
</template>

<script setup>
import { ref,defineExpose } from "vue";
import ErrorFallBack from "@/components/error/ErrorFall.vue";

const hasError = ref(false);
const error = ref(null);
// const emit = defineEmits(['errorCaptured']);

const errorCaptured = (err) => {
    hasError.value = true;
    error.value = err;
    // emit('error-captured',{err,instance,info});
    return false;
};

const resetError = () => {
    hasError.value = false;
    window.location.reload();
};
defineExpose({errorCaptured});
</script>