<template>
  <div class="modal_overlay" @click="closeModal">
    <div class="modal_pop_small" >
      <div class="smallPop popup_block_large pb-20" @click.stop>
        <div class="pop_title mt-20">
          <p>거래소별 수익금 현황</p>
        </div>
        <div class="close_modal_btn" @click="closeModal"></div>
        <div class="con_wrap">
          <div >
            <ul class="tabMenu_inner tab_ctgy04">
              <li :class="{ active: selectTab === 0 }" @click="changeTab(0)">정산 전</li>
              <li :class="{ active: selectTab === 1 }" @click="changeTab(1)">정산 후</li>
            </ul>
          </div>
          <div class="table-responsive text-nowrap dataTables_wrapper">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>거래소</th>
                  <th>금일 수익금</th>
                  <th>금주 수익금</th>
                  <th>금월 수익금</th>
                  <th>총 수익금</th>
                </tr>
              </thead>
              <tbody v-if="selectTab == 1">
                <tr v-for="(data, i) in exchangeArr" :key="i">
                  <td>{{ data.name }}</td>
                  <td>{{ numComma(data.daily_profit, 4) }}$</td>
                  <td>{{ numComma(data.weekly_profit, 4) }}$</td>
                  <td>{{ numComma(data.monthly_profit, 4) }}$</td>
                  <td>{{ numComma(data.total_profit, 4) }}$</td>
                </tr>
                <tr class="bg_gray">
                  <td>전체</td>
                  <td>{{ formatNum(dashboardInfo.day_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.week_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.month_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.total_profit, 4) }}$</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(data, i) in exchangeArr" :key="i">
                  <td>{{ data.name }}</td>
                  <td>{{ numComma(data.before_daily_profit, 4) }}$</td>
                  <td>{{ numComma(data.before_weekly_profit, 4) }}$</td>
                  <td>{{ numComma(data.before_monthly_profit, 4) }}$</td>
                  <td>{{ numComma(data.before_total_profit, 4) }}$</td>
                </tr>
                <tr class="bg_gray">
                  <td>전체</td>
                  <td>{{ formatNum(props.dashboardInfo.day_befor_profit, 4) }}$</td>
                  <td>{{ formatNum(props.dashboardInfo.week_befor_profit, 4) }}$</td>
                  <td>{{ formatNum(props.dashboardInfo.month_befor_profit, 4) }}$</td>
                  <td>{{ formatNum(props.dashboardInfo.total_befor_profit, 4) }}$</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
// import { computed, ref } from "vue";
import { formatNum, numComma } from "@/utils/common";
import { ref } from "vue";
const store = useStore();
const props = defineProps({
  status: { type: Number },
  dashboardInfo: { type: Object },
  exchangeArr: { type: Array },
});
const selectTab = ref(1);
const closeModal = () => {
  store.commit("admin/changeModalState", false);
};
const changeTab = (val) => {
  selectTab.value = val;
}
</script>

<style lang="scss" scoped></style>
