import api from "@/api/apiManage";

export default {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      reqLogInfo: {},
      reqMemberInfo: {
        exchange: 0,
        size: 5,
      },
      page: 1,
      //res
      dashboardInfo: {},
      totalPages: 0,
      memberList: [],
      logList : [],
    };
  },
  mutations: {
    setPage(state, str) {
      if (str == "prev") {
        state.page = state.page - 5 <= 0 ? 1 : state.page - 5;
      } else if (str == "prevEnd") {
        state.page = 1;
      } else if (str == "next") {
        state.page = state.page + 5 > state.totalPages ? state.totalPages : state.page + 5;
      } else if (str == "nextEnd") {
        state.page = state.totalPages;
      } else {
        state.page = str;
      }
    },
    setReqLogInfo(state, payload) {
      state.reqLogInfo = {
        size: 10,
        ...payload
      };
    },
  },
  actions: {
    async getDashBoard(context) {
      try {
        let response = await api.getDashBoardApi();
        context.state.dashboardInfo = response.results;
      } catch (error) {
        return;
      }
    },
    async getTotalMember(context) {
      try {
        let response = await api.getTotalMemberApi(
          context.state.reqMemberInfo,
          context.state.page
        );
        context.state.memberList = response.results;
        context.state.totalPages = response.totalPages;
      } catch (error) {
        return;
      }
    },
    async getNewMember(context) {
      try {
        let response = await api.getNewMemberApi(
          context.state.reqMemberInfo,
          context.state.page
        );
        context.state.memberList = response.results;
        context.state.totalPages = response.totalPages;
      } catch (error) {
        return;
      }
    },
    async getUidLog(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getUidLogApi(
          context.state.reqLogInfo,
          context.state.page
        );
        context.state.logList = response.results;
        context.state.totalPages = response.totalPages;
        context.state.isLoading = false;
      } catch (error) {
        return;
      }
    },
  },
};
