import { createApp } from "vue";
import App from "./App.vue";
import router from "@/routes/index.js";
import store from "@/store/index.js";
import VueApexCharts from "vue3-apexcharts";

let app = createApp(App);
// app.config.errorHandler = (err) => {
//     console.error(`Captured in Vue.config.errorHandler:`, err);
// };
// window.addEventListener('unhandledrejection', (event) => {
//   console.error('Unhandled promise rejection:', event.reason);
// });

  // app.config.errorHandler = (err, instance, info) => {
  //   console.error(`Captured in Vue.config.errorHandler:`, err);
  //   // 에러 로깅 서비스로 에러 전송 등 추가 작업
  // };
app.use(store).use(router).use(VueApexCharts).mount("#app");

