<template>
  <div class="content_wrapper">
    <div class="page_title">
      <i class="tf-icons ti ti-lock ti_box"></i>
      <h4 class="title">관리자정보</h4>
    </div>
    <div class="col-12 pl-10 pr-10 mb-20">
      <div class="card">
        <div class="card_header">
          <h5 class="card_title"><i class="ti ti-list mr-5"></i>관리자 리스트</h5>
        </div>
        <div class="card_body">
          <div class="table-responsive text-nowrap dataTables_wrapper">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>Admin ID</th>
                  <th>담당자</th>
                  <th>권한</th>
                  <th>등록일</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody v-if="adminList?.length == 0">
                <tr>
                  <td colspan="7">등록된 데이터가 없습니다.</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="admin in adminList" :key="admin.admin_id">
                  <td>{{ admin.admin_id }}</td>
                  <td>{{ admin.name }}</td>
                  <td>{{ admin.role == 1 ? "MASTER" : "GENERAL" }}</td>
                  <td>{{ admin.datetime.split("T")[0] }}</td>
                  <td>
                    <button class="btn_primary_bg" @click="changeAdminStatus(2, admin)">
                      <i class="ti ti-pencil me-1"></i>
                      Edit
                    </button>
                    <button class="btn_primary_bg" @click="changeAdminStatus(3, admin.admin_id)">
                      <i class="ti ti-pencil me-1"></i>
                      Password
                    </button>
                    <button @click="changeAdminStatus(4, admin.admin_id)" class="btn_gray_bg">
                      <i class="ti ti-trash me-1"></i> Delete
                    </button>
                    <p class="bin"></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--버튼:start-->
            <div class="row pd-15">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-primary mlr-5 mt-20" @click="changeAdminStatus(1)">
                  관리자 등록
                </button>
              </div>
            </div>
            <!--버튼:End-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- admin flag 1 = create 2 = update 3 = pwdupdate 4 = delete-->

  <AdminCreate v-if="adminModal && (adminStatus === 1 || adminStatus === 2)" :admin="info" :status=adminStatus />
  <AdminPwdEdit v-if="adminModal && adminStatus === 3" :admin="info" :status=adminStatus />
  <AdminDelete v-if="adminModal && adminStatus === 4" :admin="info" />
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import AdminCreate from "./sub/AdminCreate.vue";
import AdminPwdEdit from "./sub/AdminPwdEdit.vue";
import AdminDelete from "./sub/AdminDelete.vue";
import { useRouter } from "vue-router";
import { getItemWithExpireTime } from "@/utils/common";
const emit = defineEmits(['errorCaptured']);
const store = useStore();
const router = useRouter();
const role = computed(() => {
  return getItemWithExpireTime("userInfoObj")?.role;
});
const adminList  = computed(() =>  store.state.admin.adminList );
const adminModal = computed(() => store.state.admin.adminModal );
const adminStatus = ref(0);
const info = ref({});
const changeAdminStatus = (status, data) => {
  info.value = {};
  //create 1 , edit 2 , editPwd 3 , delete 4
  adminStatus.value = status;
  info.value = data;
  store.commit("admin/changeAdminModal", true);
};
const createFn = async () => {
  try {
    if (role.value == 1) {
      await store.dispatch("admin/getAdmin");
    } else {
      router.push('/dash-board');
    }
  } catch (error) {
    emit('errorCaptured', error);
  }
};
createFn();
</script>
