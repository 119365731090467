<template>
  <ErrorBoundary ref="errorBoundary">
    <template v-slot:default>
      <div class="layout_wrapper" v-if="mainPath !== 'login'">
        <TheAside />
        <div class="layout_page">
          <TheNavBar />
          <router-view @errorCaptured="handleError"></router-view>
        </div>
      </div>
      <div class="layout_wrapper" v-if="mainPath === 'login'">
        <router-view></router-view>
      </div>
    </template>
  </ErrorBoundary>
</template>

<script setup>
import api from "@/api/apiAuth.js";
import "@/assets/css/font.css";
import "@/assets/css/reset.css";
import "@/assets/css/style.css";
import "@/assets/js/script.js";
import ErrorBoundary from "@/components/error/ErrorBoundary.vue";
import TheNavBar from "@/components/public/TheNavBar.vue";
import TheAside from "@/components/public/TheAside.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { getItemWithExpireTime } from "./utils/common";
const errorBoundary = ref(null);
const route = useRoute();
const router = useRouter();
const store = useStore();
let token = getItemWithExpireTime("userInfoObj")?.token;
let type = getItemWithExpireTime("userInfoObj")?.type;
const mainPath = computed(() => {
  return route.path.split("/")[1];
});
const receiveLoginData = async () => {
  api.setAuthToken(token, type);
};
onMounted(() => {
  // console.log(errorBoundary.value);
  // if (errorBoundary.value) {
  //   errorBoundary.value.errorCaptured(new Error("Initial error test"));
  // }
  if (!token) {
    router.push("/login");
  } else {
    store.commit("admin/updateLoginStatus", true);
  }
});
const handleError = (error) => {
  if (errorBoundary.value) {
    errorBoundary.value.errorCaptured(error); // Ensure that errorBoundary is not null
  } else {
    console.error('ErrorBoundary is not initialized yet');
  }
};

store.watch((state) => {
  if (state.admin.loginStatus) {
    token = getItemWithExpireTime("userInfoObj")?.token;
    type = getItemWithExpireTime("userInfoObj")?.type;
    receiveLoginData();
  }
}, receiveLoginData);
</script>
