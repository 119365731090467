import api from "@/api/apiAdmin";
export default {
  namespaced: true,
  state() {
    return {
      loginInfo: {},
      adminInfo: {},
      admin_id: "",
      loginStatus: false,
      adminModal: false,
      isSideMenu : false,
      //res
      adminList: [],
      modalState: false,
      fetchStatus: "",
    };
  },
  mutations: {
    setSideMenu(state,payload){
      state.isSideMenu = payload;
    },
    setLoginInfo(state, payload) {
      state.loginInfo = {
        username: payload.username,
        password: payload.password,
      };
    },
    setAdminId(state, payload) {
      state.admin_id = payload;
    },
    setAdminInfo(state, payload) {
      // 1 = create admin
      // 2 = update admin
      // 3 = update pwd admin
      state.adminInfo = {};
      if (payload.flag === 1 || payload.flag === 2) {
        state.adminInfo = {
          admin_id: payload.admin_id,
          role: payload.role,
          memo: payload.memo,
          name: payload.name,
        };
      }
      if (payload.flag === 1 || payload.flag === 3) {
        state.adminInfo.pwd = payload.pwd;
      }
    },
    updateLoginStatus(state, payload) {
      state.loginStatus = payload;
    },
    changeModalState(state, payload) {
      state.modalState = payload;
    },
    changeAdminModal(state, payload) {
      state.adminModal = payload;
    },
    setFetchStatus(state, payload) {
      state.fetchStatus = payload;
    },
  },
  actions: {
    async userLogin(context) {
      try {
        let info = `username=${context.state.loginInfo.username}&password=${context.state.loginInfo.password}`;
        let response = await api.postTokenApi(info);
        if (response.access_token) {
          const now = new Date();
          //로그인 만료시간 30분으로 설정
          const ttl = 18000000;
          const expire = now.getTime() + ttl;
          const userInfoObj = {
            pk : response.admin,
            token: response.access_token,
            type: response.token_type,
            role: response.role,
            adminId: response.admin_id,
            expire: expire,
          };
          window.sessionStorage.setItem(
            "userInfoObj",
            JSON.stringify(userInfoObj)
          );
        }
      } catch (error) {
        return;
      }
    },
    async createAdmin(context) {
      try {
        let info = context.state.adminInfo;
        let response = await api.createAdminApi(info);
        context.state.fetchStatus = response.status;
      } catch (error) {
        return;
      }
    },
    async updateAdmin(context) {
      try {
        let admin_id = context.state.admin_id;
        let info = context.state.adminInfo;
        let response = await api.updateAdminApi(admin_id, info);
        context.state.fetchStatus = response.status;
      } catch (error) {
        return;
      }
    },
    async updateAdminPwd(context) {
      try {
        let admin_id = context.state.admin_id;
        let info = context.state.adminInfo;
        let response = await api.updateAdminPwdApi(admin_id, info);
        context.state.fetchStatus = response.status;
      } catch (error) {
        return;
      }
    },
    async deleteAdmin(context) {
      try {
        let admin_id = context.state.admin_id;
        let response = await api.deleteAdminApi(admin_id);
        context.state.fetchStatus = response.status;
      } catch (error) {
        return;
      }
    },
    async getAdmin(context) {
      try {
        let response = await api.getAdminApi();
        if (response) {
          context.state.adminList = response;
        }
      } catch (error) {
        return;
      }
    },
  },
};
